<template>
  <div class="allTemplate">
    <div class="searchflex">
      <div class="searchLeft">
        <div class="searchInput">
          <el-input
            size="mini"
            v-model="search"
            @input="getSearch()"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
          ></el-input>
        </div>
      </div>
      <div class="searchRight">
        <img src="@/assets/image/add_new.png" class="addIcon" @click="add()" />
      </div>
    </div>
    <yh-table
      tableBorder
      :tableHeight="tableHeight"
      :tableLoading="tableLoading"
      :tableHead="tableHead"
      :tableData="tableData"
    >
      <template #tableRow="{ row, item }">
        <span>{{ row[item.prop] }}</span>
      </template>
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <yh-popover
            popoverTitle="修改"
            @savePopUp="edit(scope.row)"
          ></yh-popover>
          <yh-popover
            popoverOnly="1"
            popoverTitle="删除"
            @savePopUp="del(scope.row)"
          >
            确定删除该信息吗？
          </yh-popover>
        </template>
      </el-table-column>
    </yh-table>
    <div class="pages">
      <el-pagination
        :current-page="page"
        :total="total"
        :page-size="size"
        :page-sizes="[20, 30, 40, 50]"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 新增编辑 -->
    <yh-dialog :dialogObj="dialogObj">
      <yh-form
        ref="formName"
        labelWidth="110px"
        :formRule="formRule"
        :formHead="formHead"
        :formData="formData"
      ></yh-form>
      <template #footer>
        <el-button size="mini" type="primary" v-if="prepare" @click="submit()">
          保 存
        </el-button>
        <el-button size="mini" type="primary" v-else> 保 存 </el-button>
      </template>
    </yh-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      // 表格
      tableLoading: false,
      tableHeight: "100px",
      tableHead: [
        {
          prop: "name",
          label: "真实姓名",
          fixed: false,
          minWidth: "130",
        },
        {
          prop: "preferredName",
          label: "用户名",
          fixed: false,
          minWidth: "120",
        },
        {
          prop: "email",
          label: "邮箱",
          fixed: false,
          minWidth: "130",
        },
        {
          prop: "phone",
          label: "手机号码",
          fixed: false,
          minWidth: "120",
        },
      ],
      tableData: [],
      page: 1,
      size: 20,
      total: 0,

      dialogObj: {
        dialogTitle: "新增",
        dialogOpen: false,
      },
      formRule: {
        name: [{ required: true, message: "请输入真实姓名", trigger: "blur" }],
        preferredName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      },
      formHead: [
        {
          comp: "el-input",
          label: "真实姓名",
          prop: "name",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "用户名",
          prop: "preferredName",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "邮箱",
          prop: "email",
          widthSize: 2,
        },
        {
          comp: "el-input",
          label: "手机号码",
          prop: "phone",
          widthSize: 2,
        },
      ],
      formData: {
        id: undefined,
        preferredName: undefined,
        name: undefined,
        email: undefined,
        phone: undefined,
      },
      prepare: true,
    };
  },
  created() {
    this.getHeight();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 表格
    getSearch() {
      this.page = 1;
      this.getData();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.tableLoading = true;
      this.$axios.get(
        "users",
        {
          perPage: this.size,
          page: this.page,
          q: this.search,
        },
        (res) => {
          this.tableLoading = false;
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            this.tableData = res.data;
            this.total = Number(res.headers["x-total-count"]);
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    // 新增
    add() {
      this.formData = {
        id: undefined,
        preferredName: undefined,
        name: undefined,
        email: undefined,
        phone: undefined,
      };
      this.dialogObj.dialogOpen = true;
      this.dialogObj.dialogTitle = "新增";
    },
    edit(row) {
      this.$axios.get("usersDetail", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.formData = res.data;

          this.dialogObj.dialogOpen = true;
          this.dialogObj.dialogTitle = "修改";
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    submit() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          this.prepare = false;
          if (this.formData.id) {
            this.$axios.jsonPost(
              "usersEdit",
              {
                id: this.formData.id,
                name: this.formData.name,
                preferredName: this.formData.preferredName,
                email: this.formData.email,
                phone: this.formData.phone,
                password: "123456",
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("修改成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          } else {
            this.$axios.jsonPost(
              "users",
              {
                name: this.formData.name,
                preferredName: this.formData.preferredName,
                email: this.formData.email,
                phone: this.formData.phone,
                password: "123456",
              },
              (res) => {
                this.prepare = true;
                var status = res.status;
                if (status.toString().substring(0, 1) == 2) {
                  this.dialogObj.dialogOpen = false;
                  this.$message.success("添加成功");
                  this.resetForm();
                  this.getData();
                } else {
                  this.$message.error(res.data.message);
                }
              }
            );
          }
        } else {
          this.$message.error("请填写必填项");
        }
      });
    },
    resetForm() {
      if (this.$refs.formName) {
        this.$refs.formName.resetFields();
      }
    },
    del(row) {
      this.$axios.jsonPost("usersDel", { id: row.id }, (res) => {
        var status = res.status;
        if (status.toString().substring(0, 1) == 2) {
          this.$message.success("删除成功");
          this.getData();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getHeight() {
      this.tableHeight = window.innerHeight - (50 + 40 + 60 + 30 + 45) + "px";
    },
  },
};
</script>
<style scoped>
.pages {
  text-align: right;
  padding: 5px 0;
  border: 1px solid #ddd;
}
</style>
